import styled from 'styled-components';
import CondorSpinner from './CondorSpinner';
import React from 'react';

const CenteredSpinner = styled.div`
  width: 10%;
  margin: 0 auto;
  margin-top: 80px;
`;

export default function LoadingSpinner(): JSX.Element {
  return (
    <CenteredSpinner>
      <CondorSpinner />
    </CenteredSpinner>
  );
}
