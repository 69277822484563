import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Workflow } from '../../Components/workflow/Workflow';
import { SelectTagsToTarget } from './SelectDeviceGroups';
import { FinalizeDeploy } from './FinalizeDeploy';
import { Release, Tag } from '../../gql/generated';

export interface UserDeployInfoWorkflow {
  release: Release;
  tags: Tag[];
}

export const DeployPackageWorkflow = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [userInput, setUserInput] = useState<UserDeployInfoWorkflow>({
    tags: [],
    release: location.state as Release,
  });

  const workflowSteps: {
    title: string;
    element: JSX.Element;
  }[] = [
    {
      title: 'Select Tags to Target',
      element: (
        <SelectTagsToTarget
          userInput={userInput}
          setUserInput={setUserInput}
          goToNextStep={() => setStep(step + 1)}
          goToPreviousStep={() => navigate('/')}
        />
      ),
    },
    {
      title: 'Finalize & Deploy',
      element: (
        <FinalizeDeploy
          userInput={userInput}
          goToNextStep={() => navigate('/')}
          goToPreviousStep={() => setStep(step - 1)}
        />
      ),
    },
  ];

  return (
    <Workflow
      title={'Deploy a Release'}
      steps={workflowSteps}
      currentStep={step}
    />
  );
};
