import Validators from '@canopysecurity/component-library/utils/Validators';

export function releaseVersion(input: string): string {
  const regex = /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)$/;
  if (input) {
    if (regex.test(input)) {
      return '';
    } else return 'Please enter a correctly formatted release version (ex: 0.2.15)';
  }
  return Validators.mandatory(input);
}

export function serialNumber(input: string): string {
  const regex = /^([0-9a-zA-Z]{20})$/;
  if (input) {
    if (regex.test(input)) {
      return '';
    } else return 'Please enter a serial number';
  }
  return Validators.mandatory(input);
}
