import React from 'react';
import { DisplayDataRow } from '../../Components/DisplayDataRow';
import PageTitleAndSub from '../../Components/PageTitleAndSub';
import SelectedArtifactsTable from '../../Components/SelectedArtifactsTable';
import WorkflowFooter from '../../Components/workflow/WorkflowFooter';
import { PageContainer } from '../../StyledComponents/PageContainer';
import { TitleText } from '../../StyledComponents/Typography';
import { UserDeployInfoWorkflow } from './DeployPackageWorkflow';
import { StatusMessage } from '../../Components/StatusMessage';
import { scrollToTop } from '../../utils/Window';
import { Tag } from '../../Components/Tag';
import { useTheme } from '@canopysecurity/component-library';
import {
  Artifact,
  DeploymentInput,
  EnvironmentType,
  useCreateDeploymentMutation,
} from '../../gql/generated';
import { useEnv } from '../../Context/EnvironmentProvider';

export const FinalizeDeploy = ({
  userInput,
  goToNextStep,
  goToPreviousStep,
}: {
  userInput: UserDeployInfoWorkflow;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
}): JSX.Element => {
  const { env } = useEnv();
  const [createDeployment, { data, loading, error }] =
    useCreateDeploymentMutation();
  if (data || error) {
    scrollToTop();
  }
  const deploymentData: DeploymentInput = {
    envName: env.toUpperCase() as EnvironmentType,
    releaseId: userInput.release?.id ?? '',
    tagIds: userInput.tags?.map((t) => t.tagId).filter((t): t is string => !!t),
  };

  const { textStyles } = useTheme();
  const jobId = data?.createDeployment?.jobId;
  return (
    <PageContainer>
      {error && <StatusMessage text={error.message} />}
      {jobId && <StatusMessage text={'Release successful. Job ID: ' + jobId} />}
      {data ? (
        <TitleText
          textStyle={textStyles.bodySemiBold}
          style={{
            textDecoration: 'underline',
            marginTop: '2%',
            marginBottom: '1%',
          }}
        >
          Details
        </TitleText>
      ) : (
        <PageTitleAndSub
          title={'Finalize & Deploy'}
          subtitle={
            'Review the following data and finalize the release for deployment.'
          }
        />
      )}
      <DisplayDataRow
        label={'Release Title'}
        data={userInput.release?.semver ?? undefined}
      />
      <DisplayDataRow
        label={'Device Type'}
        data={userInput.release?.deviceType ?? undefined}
      />
      <DisplayDataRow
        label={'Selected Artifacts'}
        data={
          <SelectedArtifactsTable
            selections={(userInput.release?.artifacts ?? []).reduce(
              (acc: Record<any, Artifact>, current) => {
                if (!current?.typeName) {
                  return acc;
                }
                return {
                  ...acc,
                  [current.typeName]: current,
                };
              },
              {},
            )}
          />
        }
      />
      <DisplayDataRow label={'Environment'} data={env.toUpperCase()} />
      <DisplayDataRow
        label="Tags"
        data={userInput.tags.map((tag) => (
          <Tag text={tag.tagName ?? 'unknown'} key={tag.tagId ?? 'unknown'} />
        ))}
      />
      <WorkflowFooter
        primaryButton={{
          action: () => {
            if (data) {
              goToNextStep();
            } else {
              (async () => {
                await createDeployment({
                  variables: {
                    deployment: {
                      ...deploymentData,
                    },
                  },
                });
              })();
            }
          },
          name: data ? 'Close' : 'Deploy',
          disabled: false,
          loading,
        }}
        previousButton={{
          action: () => {
            goToPreviousStep();
          },
          visible: data ? false : true,
        }}
      />
    </PageContainer>
  );
};
