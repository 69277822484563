import React from 'react';
import styled from 'styled-components';
import { StyledText, useTheme } from '@canopysecurity/component-library';

const LabelContainer = styled.div`
  flex: 0.2;
`;
const DataContainer = styled.div`
  flex: 0.8;
`;
const RowContainer = styled.div`
  display: flex;
  padding: 10px 0 10px 0;
`;

export const DisplayDataRow = ({
  label,
  data,
}: {
  label: string;
  data?: string | JSX.Element | JSX.Element[];
}) => {
  const { textStyles } = useTheme();
  return (
    <RowContainer>
      <LabelContainer>
        <StyledText textStyle={textStyles.bodySemiBold} testID={label}>
          {label}
        </StyledText>
      </LabelContainer>
      <DataContainer>
        {typeof data === 'string' ? (
          <StyledText textStyle={textStyles.bodySemiBold} testID={label}>
            {data}
          </StyledText>
        ) : (
          data
        )}
      </DataContainer>
    </RowContainer>
  );
};
