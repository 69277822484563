import React from 'react';
import { TitleText } from '../StyledComponents/Typography';
import styled from 'styled-components';
import { StyledText, useTheme } from '@canopysecurity/component-library';

const TitleContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;

const PageTitleAndSub = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string;
}) => {
  const { textStyles } = useTheme();
  return (
    <TitleContainer>
      <TitleText testID="page-title">{title}</TitleText>
      <StyledText textStyle={textStyles.header1Medium} testID={title}>
        {subtitle}
      </StyledText>
    </TitleContainer>
  );
};
export default PageTitleAndSub;
