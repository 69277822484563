import React, { Dispatch, SetStateAction, useCallback } from 'react';
import WorkflowFooter from '../../Components/workflow/WorkflowFooter';
import { UserDeployInfoWorkflow } from './DeployPackageWorkflow';
import { useTheme } from '@canopysecurity/component-library';
import {
  HeadingText,
  ParagraphText,
  SectionTitleText,
} from '../../StyledComponents/Typography';
import styled from 'styled-components';
import pixelRatio from '@canopysecurity/component-library/utils/pixelRatio';
import { Tag, useGetTagsQuery } from '../../gql/generated';
import { DeployBaseTableRow } from '../../StyledComponents/Table';
import CondorSpinner from '../../Components/CondorSpinner';
import cutecondor from '../../assets/cute-condor.png';

const FormLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${pixelRatio.heightPercentageToDP('.2')}em 0px
    ${pixelRatio.heightPercentageToDP('0.1')}em;
  justify-content: flex-start;
  align-items: center;
`;

const FormLabel = styled.label`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
`;

const TableContainer = styled.div`
  margin-top: 3%;
  padding: 0 5% 0 5%;
  height: 40vh;
  margin-bottom: 4em;
`;

const TableRows = styled.div`
  grid-template-columns: 25fr 25fr 50fr;
  display: grid;
  width: 100%;
  height: 80%;
  overflow-y: auto;
`;

function TagRow({
  tag,
  index,
  onChange,
  isSelected,
}: {
  tag: Tag;
  index: number;
  isSelected: boolean;
  onChange: (tag: Tag) => void;
}) {
  const theme = useTheme();
  return (
    <DeployBaseTableRow
      key={tag.tagId}
      style={{
        backgroundColor:
          index % 2 === 0 ? theme.secondary.darker : theme.background.surface,
      }}
    >
      <div
        style={{
          marginRight: '0.6em',
          justifySelf: 'stretch',
          marginLeft: 'auto',
          maxWidth: 200,
          width: '100%',
        }}
      >
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => {
            onChange(tag);
          }}
        />
      </div>
      <ParagraphText style={{ alignSelf: 'center' }}>
        {tag.tagName}
      </ParagraphText>
      <ParagraphText style={{ alignSelf: 'center' }}>
        TODO: Estimated Number of Devices
      </ParagraphText>
    </DeployBaseTableRow>
  );
}

export const SelectTagsToTarget = ({
  userInput,
  setUserInput,
  goToPreviousStep,
  goToNextStep,
}: {
  userInput: UserDeployInfoWorkflow;
  setUserInput: Dispatch<SetStateAction<UserDeployInfoWorkflow>>;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
}): JSX.Element => {
  const { data, loading } = useGetTagsQuery({
    variables: {
      input: {},
    },
    fetchPolicy: 'no-cache',
  });
  const tags = (data?.getTags?.results ?? []).filter(Boolean) as Tag[];

  const toggleTag = useCallback(
    (tag: Tag) => {
      const existingTags = userInput.tags.map((t) => t.tagId);
      setUserInput((prev) => ({
        ...userInput,
        tags: existingTags.includes(tag.tagId)
          ? prev.tags.filter((t) => t.tagId !== tag.tagId)
          : [...prev.tags, tag],
      }));
    },
    [userInput, setUserInput],
  );

  const submitValuesRef: React.RefObject<any> = React.createRef();

  const onSubmit = (): void => {
    if (submitValuesRef.current) submitValuesRef.current();
  };

  const clearTags = () => {
    setUserInput((prev) => ({ ...prev, tags: [] }));
  };

  return (
    <div style={{ marginTop: '30px' }}>
      <>
        <FormLabelContainer>
          <FormLabel>Deploy To</FormLabel>
        </FormLabelContainer>

        {loading ? (
          <CondorSpinner />
        ) : (
          <TableContainer>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingBottom: '16px',
              }}
            >
              <SectionTitleText>Available Tags to Target</SectionTitleText>
              <div
                style={{
                  marginLeft: 'auto',
                  cursor: 'pointer',
                  paddingRight: '8px',
                }}
                onClick={clearTags}
              >
                <HeadingText>Clear All</HeadingText>
              </div>
            </div>
            {/* <DeployPackageTableHeaderRow>
              {DeployPackageTableHeader.map((header) => (
                <SectionTitleText key={header}>{header}</SectionTitleText>
              ))}
            </DeployPackageTableHeaderRow> */}
            {!tags?.length ? (
              <img
                src={cutecondor}
                alt="No tags available to target on"
                style={{ maxHeight: '70%' }}
              />
            ) : (
              <TableRows>
                {tags.map((tag, index) => (
                  <TagRow
                    key={tag.tagId}
                    tag={tag}
                    index={index}
                    onChange={toggleTag}
                    isSelected={
                      !!tag.tagId &&
                      !!userInput.tags?.find((t) => t.tagId === tag.tagId)
                    }
                  />
                ))}
              </TableRows>
            )}
          </TableContainer>
        )}

        <WorkflowFooter
          primaryButton={{
            action: () => {
              onSubmit();
              goToNextStep();
            },
            name: 'Continue',
            disabled: !userInput.tags.length,
          }}
          previousButton={{
            action: () => {
              goToPreviousStep();
            },
            visible: true,
          }}
        />
      </>
    </div>
  );
};
