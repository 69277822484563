import React from 'react';
import { isEmpty } from 'lodash';
import {
  SelectedTable,
  SelectedTableHeaderRow,
  SelectedTableRow,
  SelectedTableSeparator,
} from '../StyledComponents/Table';
import { ParagraphText } from '../StyledComponents/Typography';
import { useTheme } from '@canopysecurity/component-library';
import { Artifact } from '../gql/generated';
export interface SelectedArtifactsTableProps {
  selections: Record<any, Artifact>;
}
const selectedTableHeader = [
  'ARTIFACT CATEGORY',
  'ARTIFACT NAME',
  'DEPENDENT ON',
];
export default function SelectedArtifactsTable({
  selections,
}: SelectedArtifactsTableProps) {
  const noSelections = () => {
    return Object.keys(selections).reduce<boolean>((acc, curr) => {
      return acc && isEmpty(selections[curr]);
    }, true);
  };
  const { textStyles } = useTheme();
  return (
    <SelectedTable>
      <SelectedTableHeaderRow>
        {selectedTableHeader.map((header) => (
          <ParagraphText key={header} textStyle={textStyles.bodySemiBold}>
            {header}
          </ParagraphText>
        ))}
      </SelectedTableHeaderRow>
      <SelectedTableSeparator />
      {noSelections() ? (
        <SelectedTableRow>
          <ParagraphText style={{ padding: 8 }}>
            No artifacts currently selected
          </ParagraphText>
        </SelectedTableRow>
      ) : (
        Object.keys(selections)
          .filter((selection) => !isEmpty(selections[selection]))
          .map((selection) => (
            <SelectedTableRow key={selection}>
              <ParagraphText>{selection}</ParagraphText>
              <ParagraphText>{selections[selection].semver}</ParagraphText>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {Array.isArray(selections[selection].dependencies) &&
                (selections[selection].dependencies?.length ?? 0) > 0 ? (
                  selections[selection].dependencies?.map(
                    (dependent, index) => (
                      <ParagraphText
                        key={dependent?.semver}
                        style={[index !== 0 && { paddingTop: 8 }]}
                      >
                        {dependent?.typeName}-{dependent?.semver}
                      </ParagraphText>
                    ),
                  )
                ) : (
                  <></>
                )}
              </div>
            </SelectedTableRow>
          ))
      )}
    </SelectedTable>
  );
}
