import React from 'react';
import { WorkflowHeader } from './WorkflowHeader';

export const Workflow = ({
  steps,
  title,
  currentStep,
}: {
  steps: { title: string; element: JSX.Element }[];
  title: string;
  currentStep: number;
}) => {
  return (
    <div>
      <WorkflowHeader
        stepTitles={steps.map((step) => step.title)}
        currentStepTitle={steps[currentStep].title}
        titleText={title}
      />
      <div>{steps[currentStep].element}</div>
    </div>
  );
};
