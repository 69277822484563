import {
  StyledText,
  useTheme,
  withTheme,
} from '@canopysecurity/component-library';
import React from 'react';
import styled from 'styled-components';

export const TitleText = ({ style, children }: any) => {
  const { textStyles } = useTheme();
  return (
    <StyledText
      textStyle={textStyles.header2Bold}
      style={{
        textAlign: 'left',
        display: 'flex',
        ...style,
      }}
    >
      {children}
    </StyledText>
  );
};

export const ParagraphText = ({ children, ...rest }: any) => {
  const { textStyles } = useTheme();
  return (
    <StyledText
      {...rest}
      textStyle={textStyles.bodyMedium}
      style={{
        textAlign: 'left',
        display: 'flex',
      }}
    >
      {children}
    </StyledText>
  );
};

export const HeadingText = ({ children }: any) => {
  const { textStyles } = useTheme();
  return (
    <StyledText
      textStyle={textStyles.bodySemiBold}
      style={{
        textAlign: 'left',
        textDecorationLine: 'underline',
      }}
    >
      {children}
    </StyledText>
  );
};

export const ModalTitleText = ({ children }: any) => {
  const theme = useTheme();
  return (
    <StyledText
      textStyle={theme.textStyles.header2Bold}
      style={{
        textAlign: 'left',
        display: 'flex',
        color: theme.colors.primaryOrange,
      }}
    >
      {children}
    </StyledText>
  );
};

export const ModalBodyText = ({
  children,
  bold = false,
}: {
  children: React.ReactNode;
  bold?: boolean;
}) => {
  const { textStyles } = useTheme();
  return (
    <StyledText
      textStyle={bold ? textStyles.bodySemiBold : textStyles.bodyMedium}
      style={{
        textAlign: 'left',
        display: 'flex',
      }}
    >
      {children}
    </StyledText>
  );
};

export const InlineBreadcrumbText = ({ children, ...rest }: any) => {
  return (
    <StyledText {...rest} style={{ display: 'inline' }}>
      {children}
    </StyledText>
  );
};

export const SectionTitleText = ({ children }: any) => {
  const { textStyles } = useTheme();
  return (
    <StyledText
      textStyle={textStyles.header1Bold}
      style={{
        display: 'flex',
        textAlign: 'left',
      }}
    >
      {children}
    </StyledText>
  );
};

export const LogoText = withTheme(
  styled.h1`
    ${({ theme }) => theme.textStyles.header4Bold};
    line-height: 1;
    color: ${({ theme }) => theme.text.secondary};
  `,
);
