import { withTheme } from '@canopysecurity/component-library';
import styled from 'styled-components';

const BaseTable = styled.div`
  height: fit-content;
  border-radius: 8px;
  display: grid;
  gap: 10px;
  padding: 3%;
`;

export const BaseTableRow = styled.div`
  display: grid;
  border-radius: 8px;
  grid-column: span 5;
  padding: 5px;
`;

export const SelectedTable = withTheme(styled(BaseTable)`
  grid-template-columns: 33fr 33fr 34fr;
  background-color: ${({ theme }) => theme.background.main};
  border: 1px solid ${({ theme }) => theme.text.main};
  margin-top: 20px;
`);

export const SelectedTableRow = styled(BaseTableRow)`
  grid-template-columns: 33% 33% 34%;
  padding: 10px 0 10px 10px;
  border-radius: 0px;
`;

export const SelectedTableHeaderRow = styled(SelectedTableRow)`
  background-color: unset;
`;

export const SelectedTableSeparator = styled.div`
  height: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  grid-column-start: 1;
  grid-column-end: 4;
`;

export const HomeBaseTableRow = styled(BaseTableRow)`
  border-radius: unset;
  grid-template-columns: 20fr 35fr 15fr 15fr;
  justify-items: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
`;

export const DeployBaseTableRow = styled(HomeBaseTableRow)`
  grid-template-columns: 20fr 20fr 20fr 20fr 20fr 20fr;
`;
