import React from 'react';
import condor from '../assets/front-logo.svg';
import styled from 'styled-components';

const SpinningImage = styled.img`
  // This animation is defined in our global css file
  animation: spin 1s linear infinite;
`;

const CenteredSpinner = styled.div`
  width: 10%;
  margin: 0 auto;
  margin-top: 80px;
`;

const CondorSpinner = () => {
  return (
    <CenteredSpinner>
      <SpinningImage className="condor" src={condor} alt="Loading" />
    </CenteredSpinner>
  );
};

export default CondorSpinner;
