import React, { Dispatch, SetStateAction } from 'react';
import { Form } from '@canopysecurity/component-library';
import Validators from '@canopysecurity/component-library/utils/Validators';
import styled from 'styled-components';
import LoadingSpinner from '../../Components/LoadingSpinner';
import { releaseVersion } from '../../utils/Validators';
import WorkflowFooter from '../../Components/workflow/WorkflowFooter';
import { UserInputWorkflow } from './types';
import { DeviceTypeName, useGetDeviceTypesQuery } from '../../gql/generated';

const FormContainer = styled.div`
  width: 50%;
`;

export const ReleaseInfo = ({
  userInput,
  setUserInput,
  goToNextStep,
  goToPreviousStep,
}: {
  userInput: UserInputWorkflow;
  setUserInput: Dispatch<SetStateAction<UserInputWorkflow>>;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
}): JSX.Element => {
  const { data, loading } = useGetDeviceTypesQuery();
  const submitValuesRef: React.RefObject<any> = React.createRef();

  const onSubmit = (): void => {
    if (submitValuesRef.current) submitValuesRef.current();
  };

  return (
    <div style={{ marginTop: '30px' }}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <FormContainer>
            <Form
              action={({
                DeviceType: deviceType,
                ReleaseVersion: semver,
              }): void => {
                setUserInput(
                  (previousState: UserInputWorkflow): UserInputWorkflow => ({
                    ...previousState,
                    deviceType: deviceType as DeviceTypeName,
                    semver,
                    artifactIds:
                      previousState.deviceType === deviceType
                        ? previousState.artifactIds
                        : {},
                  }),
                );
                goToNextStep();
              }}
              submitValuesRef={submitValuesRef}
              fields={{
                DeviceType: {
                  label: 'Device Type',
                  defaultValue: userInput.deviceType,
                  validator: Validators.mandatory,
                  isDropDown: true,
                  inputProps: {
                    items: (data?.__type?.enumValues ?? []).map(
                      (type) => type.name,
                    ),
                    placeholder: 'Select vehicle type',
                  },
                },
                ReleaseVersion: {
                  label: 'Release Version',
                  defaultValue: userInput.semver,
                  validator: releaseVersion,
                  inputProps: {
                    placeholder: '  x.y.z',
                  },
                  renderTopRightButton: () => (
                    <a
                      href="https://semver.org"
                      target="_blank"
                      rel="noreferrer"
                      style={{ marginBottom: 16 }}
                    >
                      See More Info
                    </a>
                  ),
                },
              }}
            />
          </FormContainer>
          <WorkflowFooter
            primaryButton={{
              action: () => {
                onSubmit();
              },
              name: 'Continue',
              disabled: false,
            }}
            previousButton={{
              action: () => {
                goToPreviousStep();
              },
              visible: true,
            }}
          />
        </>
      )}
    </div>
  );
};
