import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%
  }
  #root {
    height: 100%;
    overflow-y: auto;
  }
  h1 {
    padding: 0;
    margin: 0;
  }
  /* Used by condor loading spinner */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
`;

export default GlobalStyle;
