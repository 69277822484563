import React, { useState } from 'react';
import { SelectArtifacts } from './SelectArtifacts';
import { ReleaseInfo } from './ReleaseInfo';
import { ReviewRelease } from './ReviewRelease';
import { useNavigate } from 'react-router-dom';
import { UserInputWorkflow } from './types';
import { Workflow } from '../../Components/workflow/Workflow';

export const ReleasePackageWorkflow = ({
  selectedStep,
}: {
  selectedStep?: number;
}) => {
  const navigate = useNavigate();
  const [step, setStep] = useState(selectedStep ?? 0);
  const [userInput, setUserInput] = useState<UserInputWorkflow>({
    semver: '',
    description: '',
    artifactIds: {},
    releaseNotes: '',
    deviceType: undefined,
  });

  const workflowSteps: {
    title: string;
    element: JSX.Element;
  }[] = [
    {
      title: 'Release Information',
      element: (
        <ReleaseInfo
          userInput={userInput}
          setUserInput={setUserInput}
          goToNextStep={() => setStep(step + 1)}
          goToPreviousStep={() => navigate('/')}
        />
      ),
    },
    {
      title: 'Select Artifacts',
      element: (
        <SelectArtifacts
          userInput={userInput}
          setUserInput={setUserInput}
          goToNextStep={() => setStep(step + 1)}
          goToPreviousStep={() => setStep(step - 1)}
        />
      ),
    },
    {
      title: 'Review Release',
      element: (
        <ReviewRelease
          userInput={userInput}
          goToNextStep={() => navigate('/')}
          goToPreviousStep={() => setStep(step - 1)}
        />
      ),
    },
  ];

  return (
    <Workflow
      title={'Create a New Release Package'}
      steps={workflowSteps}
      currentStep={step}
    />
  );
};
