import React from 'react';
import { StyledButton } from '@canopysecurity/component-library';
import { useNavigate } from 'react-router-dom';
import PageTitleAndSub from '../../Components/PageTitleAndSub';
import styled from 'styled-components';
import { useOktaAuth } from '@okta/okta-react';
import { TagsTable } from './TagsTable';
import { DeviceTagsTable } from './DeviceTagsTable';

const Borderline = styled.div`
  margin-top: 4%;
  border-top: 1px solid rgba(0, 0, 0, 0.5);
`;

const ButtonWrapper = styled.div`
  width: 30%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ManageTagsPage = () => {
  const navigate = useNavigate();
  const okta = useOktaAuth();
  const name = okta.authState?.idToken?.claims?.name ?? 'Bird Enthusiast';

  return (
    <div style={{ paddingBottom: '5%' }}>
      <PageTitleAndSub
        title={'Welcome, ' + name}
        subtitle="Let's manage tags and the associated devices for deployments."
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <ButtonWrapper>
          <StyledButton onPress={() => navigate('/')} style={{ padding: '3%' }}>
            Home
          </StyledButton>
        </ButtonWrapper>
      </div>
      <Borderline />
      <TagsTable />
      <Borderline />
      <DeviceTagsTable />
    </div>
  );
};
