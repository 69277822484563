import React from 'react';
import packageInfo from '../../package.json';
import { StyledText, useTheme } from '@canopysecurity/component-library';
import { StyleSheet, View } from 'react-native';
import Constants from 'expo-constants';

const createStyles = () =>
  StyleSheet.create({
    semverContainer: {
      paddingVertical: '3%',
      flexDirection: 'row',
    },
  });

export function Semver() {
  const commitHash = Constants?.expoConfig?.extra?.getCommitHash as string;
  const styles = createStyles();
  const { textStyles } = useTheme();

  return (
    <View style={styles.semverContainer}>
      <StyledText textStyle={textStyles.bodyMedium}>
        {packageInfo.version}
      </StyledText>
      {commitHash && (
        <StyledText textStyle={textStyles.bodyMedium}>
          -{commitHash.slice(0, 5)}
        </StyledText>
      )}
    </View>
  );
}
