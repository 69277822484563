import React from 'react';
import styled from 'styled-components';
import {
  InlineBreadcrumbText,
  TitleText,
} from '../../StyledComponents/Typography';
import { useTheme } from '@canopysecurity/component-library';

const WorkflowTrackerContainer = styled.div`
  margin-top: 40px;
`;

const ThinLine = styled.div`
  height: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
`;

export const WorkflowHeader = ({
  stepTitles,
  currentStepTitle,
  titleText,
}: {
  stepTitles: string[];
  currentStepTitle: string;
  titleText: string;
}) => {
  const { textStyles } = useTheme();
  return (
    <WorkflowTrackerContainer>
      <TitleText>{titleText}</TitleText>
      <ThinLine />
      {stepTitles.map((stepTitle, index) => {
        return (
          <React.Fragment key={stepTitle}>
            <InlineBreadcrumbText
              textStyle={
                stepTitle === currentStepTitle
                  ? textStyles.bodySemiBold
                  : textStyles.bodyMedium
              }
            >
              {stepTitle}
            </InlineBreadcrumbText>
            {index < stepTitles.length - 1 && <span> &gt; </span>}
          </React.Fragment>
        );
      })}
      <ThinLine />
    </WorkflowTrackerContainer>
  );
};
