import { StyledButton, withTheme } from '@canopysecurity/component-library';
import React from 'react';
import styled from 'styled-components';

const BufferParentPageUnderScroll = styled.div`
  padding-top: 80px;
`;

const FooterContainer = withTheme(
  styled.div`
    display: flex;
    background-color: ${({ theme }) => theme.secondary.darkest};
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    flex-direction: row;
    margin-top: 40px;
    padding: 20px 0 20px 0;
    border-top: 1px solid ${({ theme }) => theme.secondary.darkest};
    justify-content: space-between;
  `,
);

const ButtonContainer = styled.div`
  flex: 0.2;
  @media (max-width: 768px) {
    flex-grow: 1;
  }
  margin: 0 1em 0 1em;
`;

const WorkflowFooter = (props: {
  primaryButton: {
    action: () => void;
    name: string;
    disabled: boolean;
    loading?: boolean;
  };
  previousButton: { action: () => void; visible: boolean };
}) => {
  return (
    <>
      <BufferParentPageUnderScroll style={{ height: '10%' }}>
        <FooterContainer>
          <ButtonContainer>
            <StyledButton
              style={{
                display: props.previousButton.visible ? 'flex' : 'none',
                padding: '2%',
              }}
              onPress={props.previousButton.action}
              disabled={false}
            >
              Previous
            </StyledButton>
          </ButtonContainer>
          <ButtonContainer>
            <StyledButton
              testID="footer-primary-button"
              style={{ padding: '2%' }}
              onPress={props.primaryButton.action}
              disabled={props.primaryButton.disabled}
              isLoading={props.primaryButton.loading ?? false}
            >
              {props.primaryButton.name}
            </StyledButton>
          </ButtonContainer>
        </FooterContainer>
      </BufferParentPageUnderScroll>
    </>
  );
};

export default WorkflowFooter;
