import { withTheme } from '@canopysecurity/component-library';
import React from 'react';
import styled from 'styled-components';
import Header from './Header';
import { Outlet } from 'react-router-dom';

const BackgroundView = withTheme(
  styled.div`
    background-color: ${({ theme }) => theme.background.main};
    height: 100%;
    width: 100%;
  `,
);

const ThemedSection = withTheme(
  styled.section`
    padding-left: 5%;
    padding-right: 5%;
    background-color: ${({ theme }) => theme.background.main};
  `,
);

export default function Navigation() {
  return (
    <BackgroundView>
      <Header />
      <ThemedSection>
        <Outlet />
      </ThemedSection>
    </BackgroundView>
  );
}
