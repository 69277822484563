import {
  StyledButton,
  ButtonRules,
  withTheme,
  useTheme,
} from '@canopysecurity/component-library';
import React, { useMemo, useState } from 'react';
import CondorSpinner from '../../Components/CondorSpinner';
import {
  useGetTagsQuery,
  Tag,
  DeviceTag,
  useGetDeviceTagsQuery,
} from '../../gql/generated';
import {
  SectionTitleText,
  ParagraphText,
} from '../../StyledComponents/Typography';
import styled from 'styled-components';
import cutecondor from '../../assets/cute-condor.png';
import {
  DeployBaseTableRow,
  HomeBaseTableRow,
} from '../../StyledComponents/Table';
import { AssociateTagModal } from './AssociateTagModal';
import { DisassociateTagModal } from './DisassociateTagModal';

const TableContainer = styled.div`
  margin-top: 3%;
  padding: 0 5% 0 5%;
  height: 40vh;
  margin-bottom: 4em;
`;

const TableRows = styled.div`
  grid-template-columns: 25fr 25fr 50fr;
  display: grid;
  width: 100%;
  height: 80%;
  overflow-y: auto;
`;

const TagTableHeaderRow = withTheme(styled(HomeBaseTableRow)`
  display: grid;
  grid-template-columns: 20fr 20fr;
  background-color: ${({ theme }) => theme.background.main};
`);

function DeviceRow({
  serialNumber,
  deviceTags,
  index,
  refetch,
}: {
  serialNumber: string;
  deviceTags: DeviceTagWithName[];
  index: number;
  refetch: () => void;
}) {
  const theme = useTheme();

  const [isDisassociateTagModalVisible, setIsDisassociateTagModalVisible] =
    useState<boolean>(false);

  return (
    <>
      <DeployBaseTableRow
        style={{
          backgroundColor:
            index % 2 === 0 ? theme.secondary.darker : theme.background.surface,
        }}
      >
        <div
          style={{
            marginRight: '0.6em',
            justifySelf: 'stretch',
            marginLeft: 'auto',
            maxWidth: 200,
            width: '100%',
          }}
        >
          {/* <Checkbox checked={isSelected} ={onChange} displayText="" /> */}
        </div>
        <ParagraphText style={{ alignSelf: 'center' }}>
          {serialNumber}
        </ParagraphText>
        <ParagraphText style={{ alignSelf: 'center' }}>
          {deviceTags.map((t) => t.tagName).join(', ')}
        </ParagraphText>
        <StyledButton
          onPress={() => {
            setIsDisassociateTagModalVisible(true);
          }}
          buttonRules={ButtonRules.primaryA}
        >
          Disassociate Tags
        </StyledButton>
      </DeployBaseTableRow>
      <DisassociateTagModal
        tags={deviceTags.map((d) => ({ tagId: d.tagId, tagName: d.tagName }))}
        serialNumber={serialNumber}
        isVisible={isDisassociateTagModalVisible}
        onClose={() => setIsDisassociateTagModalVisible(false)}
        refetch={refetch}
      />
    </>
  );
}

type DeviceTagWithName = DeviceTag & { tagName?: string | null };
export function DeviceTagsTable() {
  const { data: tagsData } = useGetTagsQuery({
    variables: {
      input: {},
    },
  });
  const tags = (tagsData?.getTags?.results ?? []).filter(Boolean) as Tag[];

  const {
    data: deviceTagsData,
    loading: loadingDeviceTagsData,
    refetch: refetchDeviceTags,
  } = useGetDeviceTagsQuery({
    variables: {
      input: {},
    },
    fetchPolicy: 'no-cache',
  });

  const [isAssociateTagModalVisible, setIsAssociateTagModalVisible] =
    useState<boolean>(false);

  const deviceTags = useMemo(
    () =>
      (
        (deviceTagsData?.getDeviceTags?.results ?? []).filter(
          Boolean,
        ) as DeviceTag[]
      )
        .map((d) => ({
          ...d,
          tagName:
            tags.find((t) => t.tagId === d.tagId)?.tagName ?? 'Unknown Tag',
        }))
        .reduce((acc: Record<string, DeviceTagWithName[]>, cur) => {
          if (!cur.serialNumber) {
            return acc;
          }
          if (!acc[cur.serialNumber]) {
            acc[cur.serialNumber] = [];
          }
          acc[cur.serialNumber].push(cur);
          return acc;
        }, {}),
    [deviceTagsData?.getDeviceTags?.results, tags],
  );

  if (loadingDeviceTagsData) {
    return <CondorSpinner />;
  }

  return (
    <>
      <TableContainer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: '16px',
          }}
        >
          <SectionTitleText>Device - Tags Associations</SectionTitleText>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              columnGap: '8px',
            }}
          >
            <StyledButton
              onPress={() => {
                setIsAssociateTagModalVisible(true);
              }}
              buttonRules={ButtonRules.primaryA}
            >
              Associate a Tag with a Device
            </StyledButton>
          </div>
        </div>

        <TagTableHeaderRow>
          <SectionTitleText>Device S/N</SectionTitleText>
          <SectionTitleText>Tags</SectionTitleText>
        </TagTableHeaderRow>
        {!Object.entries(deviceTags)?.length ? (
          <img
            src={cutecondor}
            alt="No devices have been associated with tags"
            style={{ maxHeight: '70%' }}
          />
        ) : (
          <TableRows>
            {Object.entries(deviceTags).map(([serialNumber, dT], index) => (
              <DeviceRow
                key={serialNumber}
                index={index}
                serialNumber={serialNumber}
                deviceTags={dT}
                refetch={refetchDeviceTags}
              />
            ))}
          </TableRows>
        )}
      </TableContainer>
      <AssociateTagModal
        tags={tags}
        isVisible={isAssociateTagModalVisible}
        onClose={() => setIsAssociateTagModalVisible(false)}
        refetch={refetchDeviceTags}
      />
    </>
  );
}
