import { Amplify } from 'aws-amplify';
import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  AmplifyConfigBranch,
  AmplifyConfigDev,
  AmplifyConfigProd,
  AmplifyConfigQE,
  AmplifyConfigStaging,
  AmplifyConfigType,
} from '../config/AppConfig';

const envMap = {
  branch: AmplifyConfigBranch,
  dev: AmplifyConfigDev,
  qe: AmplifyConfigQE,
  staging: AmplifyConfigStaging,
  prod: AmplifyConfigProd,
};

export type Env = 'branch' | 'dev' | 'qe' | 'staging' | 'prod';

type EnvContextType = {
  appsyncUrl: string;
  appsyncWssUrl: string;
  appsyncRegion: string;
  local: boolean;
  setEnv: React.Dispatch<React.SetStateAction<Env>>;
  env: Env;
};

const EnvContext = createContext({
  appsyncUrl: '',
  appsyncRegion: 'us-east-1',
  appsyncWssUrl: '',
  local: false,
  setEnv: () => undefined,
  env: 'staging',
} as EnvContextType);

export const EnvProvider = ({ children }: { children: React.ReactNode }) => {
  const [env, setEnv] = useState<Env>(
    (localStorage.getItem('app-env') as Env) || 'dev',
  );
  const [config, setConfig] = useState<any>();

  useEffect(() => {
    const configureAmplify = (amplifyConfig: AmplifyConfigType): void => {
      Amplify.configure(amplifyConfig);
      setConfig(amplifyConfig);
    };
    configureAmplify(envMap[env]);
  }, [env]);

  const local = window.location.href.includes('localhost');
  return (
    <EnvContext.Provider
      value={{
        appsyncRegion: config?.aws_appsync_region ?? 'us-east-1',
        appsyncUrl: config?.aws_appsync_graphqlEndpoint ?? '',
        appsyncWssUrl: config?.aws_appsync_graphqlWssEndpoint ?? '',
        local,
        setEnv,
        env,
      }}
    >
      {/* Note, if we render the children without a config being set, we run the risk of a RequireAuth forcibly logging us out */}
      {config ? children : null}
    </EnvContext.Provider>
  );
};
export function useEnv() {
  return useContext(EnvContext);
}
