import {
  StyledButton,
  ButtonRules,
  withTheme,
  useTheme,
} from '@canopysecurity/component-library';
import React, { useState } from 'react';
import CondorSpinner from '../../Components/CondorSpinner';
import { useGetTagsQuery, Tag } from '../../gql/generated';
import {
  SectionTitleText,
  ParagraphText,
} from '../../StyledComponents/Typography';
import { CreateTagModal } from './CreateTagModal';
import styled from 'styled-components';
import cutecondor from '../../assets/cute-condor.png';
import {
  DeployBaseTableRow,
  HomeBaseTableRow,
} from '../../StyledComponents/Table';

const TableContainer = styled.div`
  margin-top: 3%;
  padding: 0 5% 0 5%;
  height: 40vh;
  margin-bottom: 4em;
`;

const TableRows = styled.div`
  grid-template-columns: 25fr 25fr 50fr;
  display: grid;
  width: 100%;
  height: 80%;
  overflow-y: auto;
`;

const TagTableHeaderRow = withTheme(styled(HomeBaseTableRow)`
  display: grid;
  grid-template-columns: 20fr 20fr;
  background-color: ${({ theme }) => theme.background.main};
`);

function TagRow({ tag, index }: { tag: Tag; index: number }) {
  const theme = useTheme();
  return (
    <DeployBaseTableRow
      key={tag.tagId}
      style={{
        backgroundColor:
          index % 2 === 0 ? theme.secondary.darker : theme.background.surface,
      }}
    >
      <div
        style={{
          marginRight: '0.6em',
          justifySelf: 'stretch',
          marginLeft: 'auto',
          maxWidth: 200,
          width: '100%',
        }}
      >
        {/* <Checkbox checked={isSelected} ={onChange} displayText="" /> */}
      </div>
      <ParagraphText style={{ alignSelf: 'center' }}>
        {tag.tagName}
      </ParagraphText>
      <ParagraphText style={{ alignSelf: 'center' }}>
        TODO: Estimated Number of Devices
      </ParagraphText>
    </DeployBaseTableRow>
  );
}

export function TagsTable() {
  const {
    data: tagsData,
    loading: loadingTagsData,
    refetch,
  } = useGetTagsQuery({
    variables: {
      input: {},
    },
  });
  const tags = (tagsData?.getTags?.results ?? []).filter(Boolean) as Tag[];

  const [isCreateTagModalVisible, setIsCreateTagModalVisible] =
    useState<boolean>(false);

  if (loadingTagsData) {
    return <CondorSpinner />;
  }

  return (
    <>
      <TableContainer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingBottom: '16px',
          }}
        >
          <SectionTitleText>Tags</SectionTitleText>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              columnGap: '8px',
            }}
          >
            <StyledButton
              onPress={() => {
                setIsCreateTagModalVisible(true);
              }}
              buttonRules={ButtonRules.primaryA}
            >
              Create a Tag
            </StyledButton>
          </div>
        </div>
        <TagTableHeaderRow>
          <SectionTitleText>Tag</SectionTitleText>
          <SectionTitleText>Device Count</SectionTitleText>
        </TagTableHeaderRow>
        {!tags?.length ? (
          <img
            src={cutecondor}
            alt="No tags available to target on"
            style={{ maxHeight: '70%' }}
          />
        ) : (
          <TableRows>
            {tags.map((tag, index) => (
              <TagRow key={index} tag={tag} index={index} />
            ))}
          </TableRows>
        )}
      </TableContainer>
      <CreateTagModal
        isVisible={isCreateTagModalVisible}
        onClose={() => setIsCreateTagModalVisible(false)}
        refetch={refetch}
      />
    </>
  );
}
