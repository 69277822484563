import React, { createContext, useEffect, useMemo, useState } from 'react';
import {
  PromotionMatrix,
  PromotionMatrixItem,
  PromotionModalContextProps,
} from './types';
import { StatusMessage } from '../../Components/StatusMessage';
import {
  ModalBodyText,
  ModalTitleText,
} from '../../StyledComponents/Typography';
import {
  ButtonRules,
  StyledButton,
  useTheme,
} from '@canopysecurity/component-library';
import Modal from 'react-modal';
import { usePromoteReleaseMutation, Release } from '../../gql/generated';
import defaultTheme, {
  ComponentTheme,
} from '@canopysecurity/component-library/themes/defaultTheme';
import styled from 'styled-components';
import { useEnv } from '../EnvironmentProvider';
import { useOktaAuth } from '@okta/okta-react';

const promotionEnvironmentMatrix: PromotionMatrix = {
  dev: {
    promotable: true,
    promoteTo: 'staging',
    promoteRoles: ['Canopy Condor Developer'],
  },
  staging: {
    promotable: true,
    promoteTo: 'qe',
    promoteRoles: ['Canopy Condor Developer', 'Canopy Condor QE'],
  },
  qe: {
    promotable: true,
    promoteTo: 'prod',
    promoteRoles: ['Canopy Condor QE'],
  },
  prod: {
    promotable: false,
  },
};

const createStyle = (theme: ComponentTheme) => {
  return {
    button: {
      flex: 1,
      height: 40,
      marginLeft: '8px',
      marginRight: '8px',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: theme.background.surface,
      borderRadius: theme.radii.radiusLarge,
    },
    footerBackgroundContainer: {
      width: '100%',
      paddingTop: '20px',
      paddingHorizontal: '9px',
      backgroundColor: theme.background.surface,
    },
    bodyContent: {
      display: 'flex',
      marginTop: '27px',
      marginBottom: '27px',
      paddingLeft: '17px',
      paddingRight: '17px',
      justifyContent: 'space-between',
    },
  } as const;
};

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${defaultTheme.colors.lightGreyRegularE};
`;

const PromotionModalContext = createContext({} as PromotionModalContextProps);

const PromotionModalProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [release, setRelease] = useState<Release | null | undefined>(null);
  const [isPromoted, setIsPromoted] = useState<boolean>(false);
  const theme = useTheme();
  const styles = createStyle(theme);
  const { env } = useEnv();
  const okta = useOktaAuth();
  const [promotionMatrixInfo, setPromotionMatrixInfo] =
    useState<PromotionMatrixItem>(
      promotionEnvironmentMatrix[env as string] ?? { promotable: false },
    );

  useMemo(() => {
    setPromotionMatrixInfo(promotionEnvironmentMatrix[env as string]);
  }, [env]);

  const showPromotionModal = (releaseToPromote: Release) => {
    setRelease(releaseToPromote);
    setIsVisible(true);
  };

  const hidePromotionModal = () => {
    setRelease(null);
    setIsVisible(false);
  };

  const canPromotePackages = (): boolean => {
    const userRoles = okta.authState?.idToken?.claims.groups as string[];
    const hasPromotionRole =
      promotionMatrixInfo?.promoteRoles?.some((role) =>
        userRoles.includes(role),
      ) || false;
    return promotionMatrixInfo?.promotable && hasPromotionRole;
  };

  const [promoteRelease, { data, loading, error }] =
    usePromoteReleaseMutation();

  const onPromoteConfirm = async () => {
    if (!release) {
      return;
    } else {
      if (!release.id) {
        return;
      } else {
        await promoteRelease({
          variables: {
            releaseId: release.id,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (data) {
      setIsPromoted(true);
    }
  }, [data]);

  return (
    <PromotionModalContext.Provider
      value={{
        showPromotionModal,
        hidePromotionModal,
        canPromotePackages,
      }}
    >
      {children}
      <Modal
        isOpen={isVisible}
        onRequestClose={hidePromotionModal}
        style={{
          content: {
            paddingTop: 30,
            paddingBottom: 30,
            paddingLeft: 20,
            paddingRight: 20,
            display: 'flex',
            flexDirection: 'column',
            borderRadius: 8,
            minWidth: '26vw',
            position: undefined,
          },
          overlay: {
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 999,
          },
        }}
        ariaHideApp={false}
      >
        {error && (
          <div style={{ paddingBottom: '10px' }}>
            <StatusMessage text={error.message} isError={true} />
          </div>
        )}
        <Divider />
        {isPromoted && <StatusMessage text={'Release promoted successfully'} />}
        <ModalTitleText>Promote OTA Release {release?.semver}</ModalTitleText>
        <Divider />
        <div style={styles.bodyContent}>
          <ModalBodyText>New Environment</ModalBodyText>
          <ModalBodyText bold={true}>
            {promotionMatrixInfo?.promoteTo?.toUpperCase() || ''}
          </ModalBodyText>
        </div>
        <Divider />
        <div style={styles.footerBackgroundContainer}>
          <div style={styles.buttonContainer}>
            <StyledButton
              disabled={loading}
              onPress={hidePromotionModal}
              buttonRules={ButtonRules.secondary}
              style={styles.button}
            >
              Cancel
            </StyledButton>
            <StyledButton
              disabled={loading}
              onPress={onPromoteConfirm}
              buttonRules={ButtonRules.primaryA}
              style={styles.button}
            >
              {loading ? 'Promoting Release...' : 'Promote'}
            </StyledButton>
          </div>
        </div>
      </Modal>
    </PromotionModalContext.Provider>
  );
};

export { PromotionModalProvider, PromotionModalContext };
