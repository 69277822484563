import React from 'react';
import styled from 'styled-components';
import { TitleText } from '../StyledComponents/Typography';
import checkmark from '../assets/circle-checkmark.svg';
import cuteCondor from '../assets/cute-condor.png';
import { useTheme } from '@canopysecurity/component-library';

const MessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3%;
`;

export const StatusMessage = ({
  text,
  isError = false,
}: {
  text: string;
  isError?: boolean;
}) => {
  const { textStyles } = useTheme();
  return (
    <MessageContainer>
      <img
        src={isError ? cuteCondor : checkmark}
        alt="message icon"
        style={
          isError
            ? { height: '30px', width: '30px', transform: 'scaleX(-1)' }
            : {}
        }
      />
      <TitleText
        textStyle={textStyles.bodySemiBold}
        style={{ marginLeft: '15px' }}
      >
        {text}
      </TitleText>
    </MessageContainer>
  );
};
