import React from 'react';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { AUTH_TYPE, AuthOptions, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { useOktaAuth } from '@okta/okta-react';
import { useEnv } from './EnvironmentProvider';

export const ApolloClientProvider = (props: {
  children: React.ReactNode;
}): JSX.Element => {
  const { authState } = useOktaAuth();

  const { appsyncUrl, appsyncWssUrl, appsyncRegion } = useEnv();
  const auth: AuthOptions = {
    type: AUTH_TYPE.AWS_LAMBDA,
    token: () => {
      return authState?.accessToken?.accessToken.toString() ?? 'NO TOKEN';
    },
  };

  const httpLink = new HttpLink({ uri: appsyncUrl });

  const link = ApolloLink.from([
    createAuthLink({ url: appsyncUrl, region: appsyncRegion, auth }),
    createSubscriptionHandshakeLink(
      { url: appsyncWssUrl, region: appsyncRegion, auth },
      httpLink,
    ),
  ]);

  const apolloClient = new ApolloClient({
    link,
    cache: new InMemoryCache(),
    connectToDevTools: true,
  });

  return (
    <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>
  );
};
