import React from 'react';
import styled from 'styled-components';
import {
  CloseIcon,
  StyledText,
  useTheme,
} from '@canopysecurity/component-library';

const CloseButtonContainer = styled.span`
  cursor: pointer;
`;

export type TagProps = {
  text: string;
  onRemove?: () => void;
};
export const Tag = ({ text, onRemove = undefined }: TagProps): JSX.Element => {
  const theme = useTheme();
  return (
    <span
      style={{
        borderRadius: '5px',
        backgroundColor: theme.secondary.darkest,
        padding: '8px',
        display: 'inline-block',
        alignItems: 'center',
      }}
    >
      <StyledText
        textStyle={theme.textStyles.header1Bold}
        style={{ verticalAlign: 'top' }}
      >
        {text}
      </StyledText>
      {onRemove && (
        <CloseButtonContainer
          onClick={onRemove}
          data-testid={`${text}-tag-close-button`}
        >
          <CloseIcon testID="close-icon" />
        </CloseButtonContainer>
      )}
    </span>
  );
};
