import { useContext } from 'react';
import { PromotionModalContext } from './PromotionModalProvider';

export const usePromotionModal = () => {
  const promotionModalContext = useContext(PromotionModalContext);
  if (!promotionModalContext) {
    throw new Error(
      `Promotion Modal Context not found! Add <PromotionModalProvider> as parent`,
    );
  }
  return promotionModalContext;
};
