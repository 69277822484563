import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.svg';
import { LogoText } from '../StyledComponents/Typography';
import { StyledButton, withTheme } from '@canopysecurity/component-library';
import { Env, useEnv } from '../Context/EnvironmentProvider';
import { useOktaAuth } from '@okta/okta-react';
import { useCallback } from 'react';

const GrayHeader = withTheme(
  styled.header`
    background-color: ${({ theme }) => theme.secondary.darkest};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5% 5% 1% 5%;
  `,
);

const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
`;

const SessionContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  column-gap: 20px;
`;

const Header = () => {
  const { env, local, setEnv } = useEnv();
  const { authState, oktaAuth } = useOktaAuth();

  const login = async () => {
    await oktaAuth.signInWithRedirect();
  };

  const signOut = async () => {
    await oktaAuth.signOut();
  };

  const handleChangeEnv = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      localStorage.setItem('app-env', e.target.value);
      setEnv(e.target.value as Env);
    },
    [setEnv],
  );

  return (
    <GrayHeader>
      <LogoContainer to="/">
        <img src={logo} alt="Logo" />
        <LogoText>Condor</LogoText>
      </LogoContainer>
      <SessionContainer>
        {authState?.isAuthenticated ? (
          <StyledButton
            style={{ minWidth: '15%', maxWidth: '20%', padding: '.5%' }}
            onPress={signOut}
          >
            Sign Out
          </StyledButton>
        ) : (
          <StyledButton
            style={{ minWidth: '15%', maxWidth: '20%', padding: '.5%' }}
            onPress={login}
          >
            Sign In
          </StyledButton>
        )}
        <label
          className="env-label"
          htmlFor="env selector"
          style={{
            color: 'black',
          }}
        >
          Change Environments
        </label>
        <select
          name="env selector"
          onChange={handleChangeEnv}
          defaultValue={env}
        >
          {local && <option value="branch">Branch</option>}
          <option value="dev">Dev</option>
          <option value="qe">QE</option>
          <option value="staging">Staging</option>
          <option value="prod">Prod</option>
        </select>
      </SessionContainer>
    </GrayHeader>
  );
};

export default Header;
